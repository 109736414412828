import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { format, parseISO } from 'date-fns';

import MainTitle from '../common/MainTitle';
import Modal from '../common/Modal';
import Helper from '../common/Helper';
import Strings from '../common/Strings';
import Toasts from '../common/Toasts';

import './BackupInstructions.css';

import BackupInstructionsStrings from './BackupInstructionsStrings';
import BackupInstructionsService from './BackupInstructionsService';
import BackupInstructionEditor from './BackupInstructionEditor';


const BackupInstructions = () => {

    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showNewModal, setShowNewModal] = useState(false);

    const [cloneData, setCloneData] = useState();

    const [justCopyBackupInstructionKey, setJustCopyBackupInstructionKey] = useState(null);

    const navigate = useNavigate();
    const { backupInstructionKey } = useParams();

    const [backupInstructions, setBackupInstructions] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(Helper.defaults.PAGE_SIZE);
    const [showMore, setShowMore] = useState(false);

    const getBackupInstructions = () => {
        BackupInstructionsService.get(1, currentPage * pageSize).then(
            (response) => {
                setShowMore(response.length == pageSize);
                const all = [];
                all.push(...response);
                if (backupInstructionKey && !response.find(x => x.key == backupInstructionKey)) {
                    BackupInstructionsService.getOne(backupInstructionKey).then((backupInstructionResponse) => {
                        all.push(backupInstructionResponse);
                        setBackupInstructions(all);
                    });
                } else {
                    setBackupInstructions(all);
                }
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }

    const loadMore = () => {
        const page = currentPage ? currentPage + 1 : 1;
        BackupInstructionsService.get(page, pageSize).then((response) => {
            const all = [];
            if (backupInstructions) {
                all.push(...backupInstructions);
            }
            if (response) {
                response.forEach(element => {
                    if (!all.find((x) => x.key == element.key)) {
                        all.push(element);
                    }
                });

            }

            console.log(all);

            setBackupInstructions(all);
            setCurrentPage(page);
            setShowMore(response.length == pageSize);

        });
    }

    const deleteBackupInstruction = (e, id) => {
        if (!window.confirm("Вы уверены, что хотите удалить ресурс? Бэкапы перестанут создаваться.")) return;
        BackupInstructionsService.del(id).then(
            (response) => {
                refresh();
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }

    const activateBackupInstruction = (e, key) => {
        BackupInstructionsService.on(key).then(
            (response) => {
                refresh();
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }

    const deactivateBackupInstruction = (e, key) => {
        BackupInstructionsService.off(key).then(
            (response) => {
                refresh();
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }

    const refresh = () => {
        getBackupInstructions();
    }

    const cloneBI = (bi) => {
        setCloneData(bi);
    }

    useEffect(() => {
        refresh();
    }, []);


    const backupInstructionsRender = !backupInstructions ? "" : backupInstructions.map((backupInstruction) => {

        const databasesCount = backupInstruction.databases && backupInstruction.databases.length;
        const databaseNames = backupInstruction.databases && backupInstruction.databases.length > 0 ?
            backupInstruction.databases.map((db) => db.database).join(', ') : "";

        const directoriesCount = backupInstruction.directories && backupInstruction.directories.length;
        const directorieNames = backupInstruction.directories && backupInstruction.directories.length > 0 ?
            backupInstruction.directories.map((dir) => dir.path).join(', ') : "";

        return <div key={backupInstruction.key} className="card card-backupInstruction mb-4">
            <div className="card-body">
                <h5 className="card-title">
                    {backupInstruction.name}
                </h5>

                <div className='d-flex align-items-center'>
                    <button title="Скопировать ключ" className="btn btn-outline-primary me-3" onClick={() => {
                        navigator.clipboard.writeText(backupInstruction.key);
                        setJustCopyBackupInstructionKey(backupInstruction.key);
                        setInterval(() => { setJustCopyBackupInstructionKey(null); }, 3000);
                    }}>
                        {justCopyBackupInstructionKey != backupInstruction.key && <em className="fa fa-regular fa-copy"></em>}
                        {justCopyBackupInstructionKey == backupInstruction.key && <em className="fa fa-solid fa-check"></em>}
                    </button> Ключ: {backupInstruction.key}
                    <p className="card-text">{backupInstruction.description}</p>
                    <div className='ms-4'>Ротация: {backupInstruction.rotation}</div>
                    <div className='ms-4'>Базы данных: {databasesCount}</div>
                    <div className='ms-4'>Директории: {directoriesCount}</div>
                </div>
            </div>
            <ul className="list-group list-group-flush d-flex">
                {backupInstruction.creation_date && <li className="list-group-item">Дата создания: {format(parseISO(backupInstruction.creation_date), "dd.MM.yyyy HH:mm")}</li>}
                <li className="list-group-item">{backupInstruction.enabled ? <div className="text-success">Включен</div> : <div className="text-danger">Выключен</div>}</li>
                <li className="list-group-item d-flex">
                    <button className="btn btn-secondary" title="Клонировать" onClick={(e) => cloneBI(backupInstruction)}><i className="fa fa-copy"></i></button>
                    <button className="btn btn-secondary" title="Редактировать" onClick={(e) => navigate("/backup_instructions/" + backupInstruction.key)}><i className="fa fa-pencil"></i></button>
                    {!backupInstruction.enabled && <button className="btn btn-secondary" title="Включить" onClick={(e) => activateBackupInstruction(e, backupInstruction.key)}>Включить</button>}
                    {backupInstruction.enabled && <button className="btn btn-secondary" title="Отключить" onClick={(e) => deactivateBackupInstruction(e, backupInstruction.key)}>Отключить</button>}
                    <button onClick={(e) => deleteBackupInstruction(e, backupInstruction.key)} title="Удалить" className="btn btn-secondary"><i className="fa fa-trash-o"></i></button>
                </li>
            </ul>
            {backupInstructionKey && (backupInstruction.key == backupInstructionKey) &&
                <Modal onClose={(e) => { navigate("/backup_instructions"); /*refresh();*/ }} show={true} title="Редактирование ресурса" id={"modal" + backupInstruction.key}>
                    <BackupInstructionEditor data={backupInstruction} onUpdated={refresh} />
                </Modal>}
        </div>
    });

    return (<div>
        <div className="container-fluid">
            <MainTitle title="Бэкапы" />
            {message && (<div className="alert alert-danger mt-4">
                {message}
            </div>)}
            {successMessage && (<div className="alert alert-success mt-4">
                {successMessage}
            </div>)}
            <div className="control-panel">
                <button type="button" className="btn btn-primary nowrap" onClick={() => setShowNewModal(true)}>
                    Новый ресурс
                </button>
                {/* <div className="input-group search-panel">
                    <input type="text" className="form-control" placeholder="Найти..."
                        aria-label="Найти..." aria-describedby="button-search" />
                    <button className="btn btn-outline-secondary" type="button" id="button-search">Найти</button>
                </div> */}
            </div>

            <div>
                {backupInstructionsRender}
            </div>

            {showMore &&
                <div className='text-center'><button onClick={loadMore} className='btn btn-success'>Показать еще</button></div>}

            {showNewModal && <Modal show={true} title="Новый ресурс" id="newModal" onClose={() => setShowNewModal(false)}>
                <BackupInstructionEditor onUpdated={refresh} />
            </Modal>}
            {cloneData && <Modal show={true} title="Новый ресурс" id="newCloneModal" onClose={() => { setCloneData(null); }}>
                <BackupInstructionEditor onUpdated={refresh} cloneData={cloneData} />
            </Modal>}
            <Toasts message={message} setMessage={setMessage} />
        </div>
    </div>)
}

export default BackupInstructions